const propertyData = {
    "url": "http://545needisonst.com",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "545 NE Edison St", "city": "Hillsboro", "state":"Oregon", "zipcode": "97124"},
    "display_address": "545 NE Edison St",
    "property": {
      "listing_price": "$359,900",
      "bedrooms": "4",
      "baths": "1",
      "sq_feet": "1900",
      "lot_size": "11761",
      "rmls": "20639066",
      "built_in": "1952",
      "blurb": "Charming one-level living in established Hillsboro neighborhood has NEVER been on the market! Original features include hardwood floors, vaulted beam ceilings and wood burning fireplace. Kitchen features new paint and floors, vintage tile counters, wood cabinets and stainless steel appliances. Bonus loft on 2nd level. Newer furnace, water heater, sump pump, roof, windows, gutters and exterior paint.",
      "ammenities": [

      ]
    },
    "image_count": 21,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.189174667445!2d-122.98371798444047!3d45.52639857910174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54951ab067687b73%3A0xe4e1ff4f1b280483!2s545%20NE%20Edison%20St%2C%20Hillsboro%2C%20OR%2097124!5e0!3m2!1sen!2sus!4v1601579807870!5m2!1sen!2sus",
        "youtube":"-qHHcdSQ3mU",
        "matterport":"rze6iFv8PgF"
    }
  }

export default propertyData;

